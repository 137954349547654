body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  font-family: 'Roboto', Arial, Helvetica, sans-serif, minion-pro, serif;
  font-style: normal;
  font-weight: 500;
}

.landing {
  position: relative;
  /* background: url("./img/showcase.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
  font-family: minion-pro, serif;
  font-style: italic;
  font-weight: 500;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}
